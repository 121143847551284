import { LocalizationContextBuilder } from '@amzn/arb-tools';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import arbManifest from '@translations/arbManifest';

export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_BUNDLE = 'strings';

const localizationContextBuilder =
  new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE);

const isDevMode = process.env.NODE_ENV === 'development';
const isMockServerMode = process.env.MOCK_SERVER === 'true';

export const getHostURL = (
  url: string,
  isDevMode: boolean,
  isMockServerMode: boolean,
): string => {
  if (isDevMode && isMockServerMode) {
    return `http://0.0.0.0:4321/translations/${url}`;
  }

  if (isDevMode) {
    return `https://${process.env['REACT_APP_HOST']}:4321/translations/${url}`;
  }

  return `https://beta-us-west-2.arms-registration.pace.amazon.dev/translations/${url}`;
};

export const getMbmOption = (locale: string = DEFAULT_LOCALE) => {
  return {
    arbManifest,
    defaultLocalizationContext: localizationContextBuilder
      .withLocale(locale)
      .build(),
    resolveBundleUrl: (url?: string) => {
      if (url) {
        return getHostURL(url, isDevMode, isMockServerMode);
      }
      return undefined;
    },
  };
};

/**
 * Check if the page is supposed to be RTL or LTR based on the user's language
 */
export const isRTL = (locale: string): boolean => {
  const RTLLanguages = ['ar-AE', 'ar-SA', 'he-IL'];
  return RTLLanguages.includes(locale);
};
